.disabled {
  fill: #acacac;
}

.region {
  transition: all 0.2s ease-in-out;
  transform-origin: 50% 50%;
}

.regionHover {
  /* fill: black; */
  opacity: 0.6;
  cursor: pointer;
  /* transform: scaleZ(1.5); */
}

.seDefault {
  fill: rgba(242, 204, 255, 0.2);
}

.seActive {
  fill: rgba(242, 204, 255, 1);
}

.soDefault {
  fill: rgba(144, 145, 201, 0.2);
}

.soActive {
  fill: rgba(144, 145, 201, 1);
}

.neDefault {
  fill: rgba(255, 176, 101, 0.2);
}

.neActive {
  fill: rgba(255, 176, 101, 1);
}

.noDefault {
  fill: rgba(214, 233, 169, 0.2);
}

.noActive {
  fill: rgba(214, 233, 169, 1);
}

.cwDefault {
  fill: rgba(234, 245, 135, 0.2);
}

.cwActive {
  fill: rgba(234, 245, 135, 1);
}
