div.html p {
    color: black;
    background-color: transparent;
  }
  div.html li {
    color: black;
    background-color: transparent;
  }


  div.html img {
    margin-left: auto;
    margin-right: auto;
    max-height: 800px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  div.html iframe {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 400px;
    margin-top: 4px;
    margin-bottom: 4px;
}

div.html a {
  color:#00A3FF ;
  text-decoration: underline;
}